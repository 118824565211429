<template>
  <div>
    <v-row
      class="mb-2">
      <v-col
        sm="6"
        cols="12"
        class="ml-auto d-flex align-center"
      >
         <v-text-field
          @keyup.enter="handleContactSearch"
          v-model="contactSearch"
          label="Search"
          single-line
          hide-details
          class="mt-0 pt-0">
          </v-text-field>
         <v-btn
            icon
            @click="handleContactSearch">
            <v-icon>mdi-magnify</v-icon>
            </v-btn>
      </v-col>
    </v-row>
    <v-card :class="{ 'card-table_container--mobile' : $vuetify.breakpoint.xsOnly }">
      <v-data-table
        :headers="headers"
        :items="contacts"
        :server-items-length="pagination.itemsLength"
        :options.sync="pagination"
        :loading="loading"
        :footer-props="{'items-per-page-options':[10, 50, 100]}"
        @update:options="tablePaginated"
      >
        <template v-slot:item="{ item }">
          <tr v-if="$vuetify.breakpoint.smAndUp"
            @click="viewContactDetail($event, item)">
            <td>{{ item.date_created | moment }} <small class="text--secondary">{{ item.date_created | momenttime }}</small></td>
            <td>{{ item.business_name }}</td>
            <td>{{ item.business_owner }}</td>
            <td>{{ item.outreach_specialist_first_name }} {{ item.outreach_specialist_last_name }}</td>
            <td>{{ item.referral_source }}</td>
            <td>{{ getSource(item.contact_source) }}</td>
            <td>
              <div class="text-right">
                <v-btn class="mx-2" dark small color="primary">Edit</v-btn>
              </div>
            </td>
          </tr>
          <v-card
            v-else
            class="my-2 mx-1"
            @click="viewContactDetail($event, item)"
          >
            <v-card-subtitle class="pb-2">{{ item.date_created | moment }} <small class="text--secondary">{{ item.date_created | momenttime }}</small></v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="py-0">
              <v-list>
                <v-list-item v-if="item.business_name">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-domain
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.business_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.business_owner">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-account
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.business_owner }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.outreach_specialist_first_name && item.outreach_specialist_last_name">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-face-agent
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.outreach_specialist_first_name }} {{ item.outreach_specialist_last_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.referral_source">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-arrow-right-circle
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.referral_source }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.contact_source">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-arrow-right-circle
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ getSource(item.contact_source) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-right">
              <v-btn class="ml-auto" dark small color="primary"> Edit </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import moment from 'moment';

  export default {
    name: 'ContactsList',
    components: {

    },
    data() {
      return {
        pagination: {
          page: 1,
          itemsLength: 0,
          itemsPerPage: 10
        },
        loading: false,
        sortBy: 'date',
        sortDesc: false,
        headers: [
          {text: 'Date', value: 'date', sortable: false },
          {text: 'Business name', value: 'business', sortable: false},
          {text: 'Owner name', value: 'owner', sortable: false},
          {text: 'Outreach Specialist', value: 'outreach_specialist', sortable: false},
          {text: 'Referral Source', value: 'referral_source', sortable: false},
          {text: 'Contact source', value: 'contact_source', sortable: false},
          {text: '', value: 'action', sortable: false}
        ],
        contactSearch: "",
        searchClicked: false,
        contacts: [],
        sourceItems: []
      }
    },
    created() {
      this.getContactSource()
      .then((response) => {
        this.sourceItems = response.data.results
      });
    },
    methods: {
      ...mapActions(['getContacts', 'getContactSource']),
      getContactsList(params) {
        this.loading = true
        this.getContacts({
          search: this.contactSearch,
          limit: params.limit,
          offset: params.offset
        })
        .then((response) => {
          this.contacts = response.data.results
          this.pagination.itemsLength = response.data.count
          this.loading = false
        });
      },
      tablePaginated(event) {
        console.log('sortBy', event.sortBy['date'])
        console.log('sortDesc', event.sortDesc[0])
        this.getContactsList({search: this.contactSearch, limit: event.itemsPerPage, offset: ((event.page - 1)*event.itemsPerPage)})
      },
      deleteBusiness() {

      },
      getBusinessInfo(item) {
        return this.businesses ? this.businesses.find(business => business.id == item.business) : {};
      },
      getSpecialistInfo(item) {
        return this.specialists ? this.specialists.find(specialist => specialist.id == item.outreach_specialist) : {};
      },
      viewContactDetail(event, item) {
        this.$router.push(`edit/${item.id}`)
      },
      getSource(id) {
        if(id){
          const selectedSource = this.sourceItems.find((source) => source.id == id)
          return selectedSource.name
        } else {
          return ''
        }
      },
      handleContactSearch() {
        this.searchClicked = !this.searchClicked
        this.pagination.page = 1
        this.getContactsList({offset:0, limit: 10})
      }
    },
    filters: {
      moment: function (date) {
        return moment(date).format('MM-DD-YYYY')
      },
      momenttime: function (date) {
        return moment(date).format('(hh:mmA)')
      }
    },
    watch: {
      contactSearch(newValue, oldValue) {
        if(oldValue !== '' && newValue == '' && this.searchClicked) {
          this.handleContactSearch()
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/styles/variables.scss';
  tr {
    cursor: pointer;
  }
  ::v-deep {
    .v-data-table-header th {
      text-transform: uppercase;
    }
    tbody td a {
      color: inherit;
      &:hover {
        font-weight: bold;
        color: $primary-color;
      }
    }
    td {
      padding: 0 8px!important;
      &:first-child {
        //min-width: 110px;
        padding-left: 16px!important
      }
      &:last-child {
        padding-right: 16px!important
      }
      //min-width: 150px;
      @media only screen and (max-device-width: 768px) {
        padding: 0 8px!important
      }
    }
  }
</style>
